import React from "react";
import MainLayout from "../components/MainLayout/MainLayout";
import Rules from "../components/Rules/Rules";

const rules = () => {
	return (
		<MainLayout lang="hu" title="rules">
			<Rules />
		</MainLayout>
	);
};

export default rules;
